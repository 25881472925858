"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hkpArtAndTerminArtMatch = exports.transformHkpStatusToSimplerStatus = exports.SimpleHkpStatus = exports.translateHKPSubStatus = exports.translateHKPStatus = exports.translateHKPArt = exports.hkpGueltigkeit = exports.isHkpOffen = exports.hkpTerminCheckArten = exports.isHkpPrivat = exports.hkpArtIds = exports.HKPArtGruppen = void 0;
const types_1 = require("../../../types");
var HKPArtGruppen;
(function (HKPArtGruppen) {
    HKPArtGruppen["None"] = "-1";
    HKPArtGruppen["ZE"] = "ZE";
    HKPArtGruppen["KBr"] = "KBr";
    HKPArtGruppen["PA"] = "PA";
    HKPArtGruppen["KFO"] = "KFO";
    HKPArtGruppen["NONKFPPAKBR"] = "NONKFPPAKBR";
    HKPArtGruppen["ZEIMPLA"] = "ZEIMPLA";
    HKPArtGruppen["PrivatAlle"] = "PrivatAlle";
    HKPArtGruppen["PrivatZE"] = "PrivatZE";
    HKPArtGruppen["PrivatKBr"] = "PrivatKBr";
    HKPArtGruppen["PrivatPA"] = "PrivatPA";
    HKPArtGruppen["PrivatKFO"] = "PrivatKFO";
    HKPArtGruppen["PrivatImpla"] = "PrivatImpla";
    HKPArtGruppen["PrivatAllgemein"] = "PrivatAllgemein";
    HKPArtGruppen["KasseAlle"] = "KasseAlle";
    HKPArtGruppen["KasseZE"] = "KasseZE";
    HKPArtGruppen["KasseKBr"] = "KasseKBr";
    HKPArtGruppen["KassePA"] = "KassePA";
    HKPArtGruppen["KasseKFO"] = "KasseKFO";
})(HKPArtGruppen = exports.HKPArtGruppen || (exports.HKPArtGruppen = {}));
exports.hkpArtIds = {
    ['-1']: { name: 'Alle Arten', gruppe: 'Privat / Kasse', artIds: types_1.HKPArtTypeAlle, benchmarkKey: 'hkp' },
    ZE: {
        name: 'ZE',
        gruppe: 'Privat / Kasse',
        artIds: [types_1.HKPArtType.PRIVAT_ZE, types_1.HKPArtType.KASSE_ZE, types_1.HKPArtType.KASSE_ZE_SPEZIAL, types_1.HKPArtType.EBZ_ZER],
        benchmarkKey: 'hkpZE',
    },
    KBr: {
        name: 'KBr / FAL',
        gruppe: 'Privat / Kasse',
        artIds: [types_1.HKPArtType.PRIVAT_FA, types_1.HKPArtType.KASSE_KIEFERBRUCH, types_1.HKPArtType.EBZ_KGL, types_1.HKPArtType.EBZ_KBR],
        benchmarkKey: 'hkpKBrFAL',
    },
    PA: {
        name: 'PAR / GTR',
        gruppe: 'Privat / Kasse',
        artIds: [
            types_1.HKPArtType.PRIVAT_PA,
            types_1.HKPArtType.PRIVAT_GTR,
            types_1.HKPArtType.KASSE_PA,
            types_1.HKPArtType.KASSE_PAR,
            types_1.HKPArtType.KASSE_PA_ERGAENZUNG,
            types_1.HKPArtType.EBZ_PAR,
            types_1.HKPArtType.EBZ_PMB,
            types_1.HKPArtType.PASTATUS_P22A,
        ],
        benchmarkKey: 'hkpPAGTR',
    },
    KFO: {
        name: 'KFO',
        gruppe: 'Privat / Kasse',
        artIds: [types_1.HKPArtType.PRIVAT_KFO, types_1.HKPArtType.PRIVAT_KFO2, types_1.HKPArtType.KASSE_KFO, types_1.HKPArtType.KASSE_KFO2],
        benchmarkKey: 'hkpKFO',
    },
    NONKFPPAKBR: {
        name: 'ZE, Impla. & Private',
        gruppe: 'Privat / Kasse',
        artIds: [
            types_1.HKPArtType.PRIVAT_ZE,
            types_1.HKPArtType.KASSE_ZE,
            types_1.HKPArtType.KASSE_ZE_SPEZIAL,
            types_1.HKPArtType.EBZ_ZER,
            types_1.HKPArtType.PRIVAT,
            types_1.HKPArtType.PRIVAT_IMPL,
            types_1.HKPArtType.PRIVAT_ZE,
        ],
    },
    ZEIMPLA: {
        name: 'ZE & Impla.',
        gruppe: 'Privat / Kasse',
        artIds: [
            types_1.HKPArtType.PRIVAT_ZE,
            types_1.HKPArtType.KASSE_ZE,
            types_1.HKPArtType.KASSE_ZE_SPEZIAL,
            types_1.HKPArtType.EBZ_ZER,
            types_1.HKPArtType.PRIVAT_IMPL,
            types_1.HKPArtType.PRIVAT_ZE,
        ],
    },
    PrivatAlle: {
        name: 'Alle Privat',
        gruppe: 'Privat',
        artIds: [
            types_1.HKPArtType.PRIVAT,
            types_1.HKPArtType.PRIVAT_FA,
            types_1.HKPArtType.PRIVAT_PA,
            types_1.HKPArtType.PRIVAT_IMPL,
            types_1.HKPArtType.PRIVAT_GTR,
            types_1.HKPArtType.PRIVAT_ZE,
            types_1.HKPArtType.PRIVAT_KFO,
            types_1.HKPArtType.PRIVAT_KFO2,
        ],
        benchmarkKey: 'hkpPrivat',
    },
    PrivatZE: { name: 'ZE', gruppe: 'Privat', artIds: [types_1.HKPArtType.PRIVAT_ZE], benchmarkKey: 'hkpPrivatZE' },
    PrivatKBr: { name: 'KBr / FAL', gruppe: 'Privat', artIds: [types_1.HKPArtType.PRIVAT_FA], benchmarkKey: 'hkpPrivatKBrFAL' },
    PrivatPA: {
        name: 'PAR / GTR',
        gruppe: 'Privat',
        artIds: [types_1.HKPArtType.PRIVAT_PA, types_1.HKPArtType.PRIVAT_GTR],
        benchmarkKey: 'hkpPrivatPA',
    },
    PrivatKFO: {
        name: 'KFO',
        gruppe: 'Privat',
        artIds: [types_1.HKPArtType.PRIVAT_KFO, types_1.HKPArtType.PRIVAT_KFO2],
        benchmarkKey: 'hkpPrivatKFO',
    },
    PrivatImpla: { name: 'Impla', gruppe: 'Privat', artIds: [types_1.HKPArtType.PRIVAT_IMPL], benchmarkKey: 'hkpPrivatImpla' },
    PrivatAllgemein: {
        name: 'Privat allgemein',
        gruppe: 'Privat',
        artIds: [types_1.HKPArtType.PRIVAT],
        benchmarkKey: 'hkpPrivatAllgemein',
    },
    KasseAlle: {
        name: 'Alle Kasse',
        gruppe: 'Kasse',
        artIds: [
            types_1.HKPArtType.KASSE_ZE,
            types_1.HKPArtType.KASSE_ZE_SPEZIAL,
            types_1.HKPArtType.KASSE_KIEFERBRUCH,
            types_1.HKPArtType.KASSE_PA,
            types_1.HKPArtType.KASSE_PAR,
            types_1.HKPArtType.KASSE_PA_ERGAENZUNG,
            types_1.HKPArtType.KASSE_KFO,
            types_1.HKPArtType.KASSE_KFO2,
            types_1.HKPArtType.EBZ_KBR,
            types_1.HKPArtType.EBZ_KFO,
            types_1.HKPArtType.EBZ_KGL,
            types_1.HKPArtType.EBZ_PAR,
            types_1.HKPArtType.EBZ_PMB,
            types_1.HKPArtType.EBZ_ZER,
            types_1.HKPArtType.PASTATUS_P22A,
        ],
        benchmarkKey: 'hkpKasse',
    },
    KasseZE: {
        name: 'ZE',
        gruppe: 'Kasse',
        artIds: [types_1.HKPArtType.KASSE_ZE, types_1.HKPArtType.KASSE_ZE_SPEZIAL, types_1.HKPArtType.EBZ_ZER],
        benchmarkKey: 'hkpKasseZE',
    },
    KasseKBr: {
        name: 'KBr / FAL',
        gruppe: 'Kasse',
        artIds: [types_1.HKPArtType.KASSE_KIEFERBRUCH, types_1.HKPArtType.EBZ_KBR, types_1.HKPArtType.EBZ_KGL],
        benchmarkKey: 'hkpKasseKBrFAL',
    },
    KassePA: {
        name: 'PAR / GTR',
        gruppe: 'Kasse',
        artIds: [
            types_1.HKPArtType.KASSE_PA,
            types_1.HKPArtType.KASSE_PAR,
            types_1.HKPArtType.KASSE_PA_ERGAENZUNG,
            types_1.HKPArtType.EBZ_PAR,
            types_1.HKPArtType.EBZ_PMB,
            types_1.HKPArtType.PASTATUS_P22A,
        ],
        benchmarkKey: 'hkpKassePA',
    },
    KasseKFO: {
        name: 'KFO',
        gruppe: 'Kasse',
        artIds: [types_1.HKPArtType.KASSE_KFO, types_1.HKPArtType.KASSE_KFO2, types_1.HKPArtType.KASSE_KFO_NACHTRAG, types_1.HKPArtType.EBZ_KFO],
        benchmarkKey: 'hkpKasseKFO',
    },
};
function isHkpPrivat(artEnum) {
    switch (artEnum) {
        case types_1.HKPArtType.PRIVAT:
        case types_1.HKPArtType.PRIVAT_FA:
        case types_1.HKPArtType.PRIVAT_PA:
        case types_1.HKPArtType.PRIVAT_IMPL:
        case types_1.HKPArtType.PRIVAT_GTR:
        case types_1.HKPArtType.PRIVAT_ZE:
        case types_1.HKPArtType.PRIVAT_KFO:
        case types_1.HKPArtType.PRIVAT_KFO2:
            return true;
        case types_1.HKPArtType.KASSE_ZE_SPEZIAL:
        case types_1.HKPArtType.KASSE_ZE:
        case types_1.HKPArtType.KASSE_KFO:
        case types_1.HKPArtType.KASSE_KFO2:
        case types_1.HKPArtType.KASSE_PA:
        case types_1.HKPArtType.KASSE_PAR:
        case types_1.HKPArtType.KASSE_PA_ERGAENZUNG:
        case types_1.HKPArtType.KASSE_KIEFERBRUCH:
        case types_1.HKPArtType.PASTATUS_P22A:
        case types_1.HKPArtType.EBZ_KBR:
        case types_1.HKPArtType.EBZ_KGL:
        case types_1.HKPArtType.EBZ_KFO:
        case types_1.HKPArtType.EBZ_PAR:
        case types_1.HKPArtType.EBZ_PMB:
        case types_1.HKPArtType.EBZ_ZER:
            return false;
    }
    return false;
}
exports.isHkpPrivat = isHkpPrivat;
exports.hkpTerminCheckArten = [
    ...exports.hkpArtIds.ZE.artIds,
    ...exports.hkpArtIds.PrivatZE.artIds,
    ...exports.hkpArtIds.PrivatImpla.artIds,
];
function isHkpOffen(status) {
    switch (status) {
        case types_1.HKPStatusType.ABGELEHNT:
        case types_1.HKPStatusType.DURCHGEFUEHRT:
        case types_1.HKPStatusType.VERWORFEN:
        case types_1.HKPStatusType.SOFORTABRECHNUNG:
            return false;
        default:
            return true;
    }
}
exports.isHkpOffen = isHkpOffen;
function hkpGueltigkeit(hkp, fallbackMonths, verlaengert) {
    let monate = fallbackMonths;
    if (!isHkpPrivat(hkp.art)) {
        if (hkp.art === types_1.HKPArtType.KASSE_KIEFERBRUCH || hkp.art === types_1.HKPArtType.KASSE_PA_ERGAENZUNG) {
            monate = 12;
        }
        else if (exports.hkpArtIds.KFO.artIds.includes(hkp.art)) {
            monate = 4 * 12;
        }
        else if (exports.hkpArtIds.PA.artIds.includes(hkp.art)) {
            // TODO haben wir eine bessere idee?
            monate = 3 * 12;
        }
        else if (exports.hkpArtIds.ZE.artIds.includes(hkp.art)) {
            monate = 6;
        }
        else if (hkp.paragraph28) {
            // use fallback
        }
        else {
            monate = 12;
        }
    }
    // TODO: check if we can add 6 months for verlaengert
    if (verlaengert) {
        monate += 6;
    }
    return monate;
}
exports.hkpGueltigkeit = hkpGueltigkeit;
function translateHKPArt(artEnum) {
    switch (artEnum) {
        case types_1.HKPArtType.PRIVAT:
            return 'P';
        case types_1.HKPArtType.PRIVAT_FA:
            return 'P FA';
        case types_1.HKPArtType.PRIVAT_PA:
            return 'P PA';
        case types_1.HKPArtType.PRIVAT_IMPL:
            return 'P Impl';
        case types_1.HKPArtType.PRIVAT_GTR:
            return 'P GTR';
        case types_1.HKPArtType.PRIVAT_ZE:
            return 'P ZE';
        case types_1.HKPArtType.PRIVAT_KFO:
        case types_1.HKPArtType.PRIVAT_KFO2:
            return 'P KFO';
        case types_1.HKPArtType.KASSE_ZE_SPEZIAL:
        case types_1.HKPArtType.KASSE_ZE:
        case types_1.HKPArtType.EBZ_ZER:
            return 'K ZE';
        case types_1.HKPArtType.KASSE_KFO:
        case types_1.HKPArtType.KASSE_KFO2:
        case types_1.HKPArtType.EBZ_KFO:
            return 'K KFO';
        case types_1.HKPArtType.KASSE_KFO_NACHTRAG:
            return 'K KFO Nachtrag';
        case types_1.HKPArtType.KASSE_PA:
            return 'K PA';
        case types_1.HKPArtType.KASSE_PAR:
        case types_1.HKPArtType.PASTATUS_P22A:
        case types_1.HKPArtType.EBZ_PAR:
            return 'K PAR';
        case types_1.HKPArtType.KASSE_PA_ERGAENZUNG:
            return 'K PA Erg';
        case types_1.HKPArtType.KASSE_KIEFERBRUCH:
        case types_1.HKPArtType.EBZ_KBR:
            return 'K KBr';
        case types_1.HKPArtType.EBZ_KGL:
            return 'K KGL';
        case types_1.HKPArtType.EBZ_PMB:
            return 'K KPMB';
        default:
            return `${artEnum}`;
    }
}
exports.translateHKPArt = translateHKPArt;
function translateHKPStatus(status) {
    switch (status) {
        case types_1.HKPStatusType.EINGABE:
            return 'Eingabe';
        case types_1.HKPStatusType.ALTERNATIV:
            return 'Alternativ';
        case types_1.HKPStatusType.ÜBERARBEITEN:
            return 'Überarbeiten';
        case types_1.HKPStatusType.ABGELEHNT:
            return 'Abgelehnt';
        case types_1.HKPStatusType.TROTZABLEHNUNGANGENOMMEN:
            return 'Trotz Ablehnung angenommen';
        case types_1.HKPStatusType.GENEHMIGT:
            return 'Genehmigt';
        case types_1.HKPStatusType.DURCHGEFUEHRT:
            return 'Durchgeführt';
        case types_1.HKPStatusType.VERWORFEN:
            return 'Verworfen';
        case types_1.HKPStatusType.SOFORTABRECHNUNG:
            return 'Sofortabrechnung';
        default:
            return `${status}`;
    }
}
exports.translateHKPStatus = translateHKPStatus;
function translateHKPSubStatus(substatus) {
    switch (substatus) {
        case types_1.HKPSubStatusType.EINGABE_IN_ARBEIT:
            return 'In Arbeit';
        case types_1.HKPSubStatusType.EINGABE_ZU_PRUEFEN:
            return 'Zu Prüfen';
        case types_1.HKPSubStatusType.EINGABE_GEPRUEFT:
            return 'Geprüft';
        case types_1.HKPSubStatusType.EINGABE_BEI_PATIENT:
            return 'Bei Patient';
        case types_1.HKPSubStatusType.EINGABE_BEI_KASSE:
            return 'Bei Kasse';
        case types_1.HKPSubStatusType.EINGABE_BEI_GUTACHTER:
            return 'Bei Gutachter';
        case types_1.HKPSubStatusType.ABGELEHNT_KASSE:
            return 'Kasse';
        case types_1.HKPSubStatusType.ABGELEHNT_PATIENT:
            return 'Patient';
        case types_1.HKPSubStatusType.ABGELEHNT_GUTACHTER:
            return 'Gutachter';
        case types_1.HKPSubStatusType.GENEHMIGT_PATIENT_OHNE_TERMIN:
            return 'Patient ohne Termin';
        case types_1.HKPSubStatusType.GENEHMIGT_PATIENT_HAT_TERMIN:
            return 'Patient mit Termin';
        case types_1.HKPSubStatusType.GENEHMIGT_IN_ARBEIT:
            return 'In Arbeit';
        case types_1.HKPSubStatusType.GENEHMIGT_EINGEGLIEDERT_ERBRACHT:
            return 'Eingegliedert';
        case types_1.HKPSubStatusType.VERWORFEN_ALTERNATIVE_BEHANDLUNG_DURCHGEFUEHRT:
            return 'Alternative Behandlung durchgeführt';
        case types_1.HKPSubStatusType.VERWORFEN_ZU_TEUER:
            return 'Zu teuer';
        case types_1.HKPSubStatusType.VERWORFEN_NICHT_VERSTANDEN:
            return 'Nicht verstanden';
        case types_1.HKPSubStatusType.VERWORFEN_PATIENT_WARTET_AUF_ANRUF:
            return 'Patient wartet auf Anruf';
        case types_1.HKPSubStatusType.VERWORFEN_FALSCHE_LOESUNG:
            return 'Falsche Lösung';
        case types_1.HKPSubStatusType.VERWORFEN_PATIENT_FUEHLT_SICH_BEVORMUNDET:
            return 'Patient fühlt sich bevormundet';
        case types_1.HKPSubStatusType.VERWORFEN_BEHANDLER_UNSYMPATISCH:
            return 'Behandler unsympatisch';
        case types_1.HKPSubStatusType.VERWORFEN_UNBEHAGLICHES_PRAXISAMBIENTE:
            return 'Unbehagliches Praxisambiente';
        case types_1.HKPSubStatusType.VERWORFEN_ZAHNARZTWECHSEL_OHNE_ANGABE:
            return 'Zahnarztwechsel ohne Angabe';
        case types_1.HKPSubStatusType.VERWORFEN_KEINE_ANGABE:
            return 'Keine Angabe';
        default:
            return ``;
    }
}
exports.translateHKPSubStatus = translateHKPSubStatus;
var SimpleHkpStatus;
(function (SimpleHkpStatus) {
    SimpleHkpStatus[SimpleHkpStatus["UNBEKANNT"] = -1] = "UNBEKANNT";
    SimpleHkpStatus[SimpleHkpStatus["OFFEN"] = 0] = "OFFEN";
    SimpleHkpStatus[SimpleHkpStatus["DURCHGEFUEHRT"] = 1] = "DURCHGEFUEHRT";
    SimpleHkpStatus[SimpleHkpStatus["VERWORFEN"] = 2] = "VERWORFEN";
})(SimpleHkpStatus = exports.SimpleHkpStatus || (exports.SimpleHkpStatus = {}));
function transformHkpStatusToSimplerStatus(hkpStatus) {
    if ([
        types_1.HKPStatusType.EINGABE,
        types_1.HKPStatusType.GENEHMIGT,
        types_1.HKPStatusType.ALTERNATIV,
        types_1.HKPStatusType.ÜBERARBEITEN,
        types_1.HKPStatusType.TROTZABLEHNUNGANGENOMMEN,
    ].includes(hkpStatus)) {
        return SimpleHkpStatus.OFFEN;
    }
    if ([types_1.HKPStatusType.DURCHGEFUEHRT, types_1.HKPStatusType.SOFORTABRECHNUNG].includes(hkpStatus)) {
        return SimpleHkpStatus.DURCHGEFUEHRT;
    }
    if ([types_1.HKPStatusType.VERWORFEN, types_1.HKPStatusType.ABGELEHNT].includes(hkpStatus)) {
        return SimpleHkpStatus.VERWORFEN;
    }
    return SimpleHkpStatus.UNBEKANNT;
}
exports.transformHkpStatusToSimplerStatus = transformHkpStatusToSimplerStatus;
function hkpArtAndTerminArtMatch(hkpArt, hkpTerminTyp) {
    if (hkpTerminTyp !== types_1.HKPTerminType.OHNE) {
        if (hkpTerminTyp === types_1.HKPTerminType.ZE && exports.hkpArtIds.ZE.artIds.includes(hkpArt)) {
            return true;
        }
        if (hkpTerminTyp === types_1.HKPTerminType.PA && exports.hkpArtIds.PA.artIds.includes(hkpArt)) {
            return true;
        }
        if (hkpTerminTyp === types_1.HKPTerminType.IMPLA && exports.hkpArtIds.PrivatImpla.artIds.includes(hkpArt)) {
            return true;
        }
        if (hkpTerminTyp === types_1.HKPTerminType.PRIVAT && exports.hkpArtIds.PrivatAlle.artIds.includes(hkpArt)) {
            return true;
        }
        if (hkpTerminTyp === types_1.HKPTerminType.KFO && exports.hkpArtIds.KFO.artIds.includes(hkpArt)) {
            return true;
        }
        if (hkpTerminTyp === types_1.HKPTerminType.KBR && exports.hkpArtIds.KBr.artIds.includes(hkpArt)) {
            return true;
        }
        if (hkpTerminTyp === types_1.HKPTerminType.ALLE) {
            return true;
        }
    }
    return false;
}
exports.hkpArtAndTerminArtMatch = hkpArtAndTerminArtMatch;
