"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logObjectModificationsProxy = exports.mapObject = exports.dateRangeOverlap = exports.createEmptyDashBoardData = exports.osterSonntagAsUTCDate = exports.ostersonntag = exports.promiseAllMap = exports.slugifyStichwort = exports.sanitizeFilenameForCharly = exports.extractCharlyDateFromFileName = exports.prettyCharlyFileName = exports.simpleHashCode = exports.isTriggerTask = exports.isImportTask = exports.taskIdForTriggerRaw = exports.taskIdFromImport = exports.TASK_PREFIX_TRIGGER = exports.TASK_PREFIX_IMPORT = exports.parsePostgresVersion = exports.round = exports.getPercentage = exports.createPercentage = exports.jaNein = exports.isTrue = exports.sleep = exports.ensureLastRequestPrecedence = exports.benchmarkUnitNiceName = exports.benchmarkUnitShortName = exports.abrechnungsArtShortName = exports.abrechnungsArtNiceName = exports.abrechnungsArten = exports.terminstatusNiceName = exports.terminstatusName = exports.terminstatusKeyString = exports.hasAlarm = exports.getShortFeatures = exports.isCharlyAnalyticsCustomerByPackage = exports.isCharlyAnalyticsCustomer = exports.hasAllFeatures = exports.hasAnyFeature = exports.hasAnyR4cFeature = exports.hasFeature = exports.hasAnyR4cTabletFeature = exports.hasR4cFactoringFeature = exports.hasAnamneseFeature = exports.hasMetricsFeature = exports.hasR4cDocumentsFeature = exports.sql = void 0;
const types_1 = require("../../../types");
function sql(strings, ...values) {
    return String.raw(strings, ...values);
}
exports.sql = sql;
// export function sql(strings: TemplateStringsArray, ...values: readonly unknown[]): string {
//   // First, create the full string with placeholders and values
//   let fullString = String.raw(strings, ...values);
//   // Split the string into lines
//   let lines = fullString.split('\n');
//   // Find the number of leading whitespaces in the first line
//   let firstLineWhitespace = (lines[0].match(/^\s*/) || [''])[0].length;
//   console.log(lines);
//   // Remove that amount of leading whitespaces from all lines
//   let formattedLines = lines.map(line => line.substring(firstLineWhitespace));
//   // Join the lines back into a full string and return
//   return formattedLines.join('\n');
// }
function hasR4cDocumentsFeature(profile) {
    return hasAnyFeature(profile, ...types_1.DOCUMENTSFEATURES, types_1.FEATURES.ANAMNESE_DIAMOND);
}
exports.hasR4cDocumentsFeature = hasR4cDocumentsFeature;
function hasMetricsFeature(profile) {
    return hasAnyFeature(profile, types_1.FEATURES.METRICS);
}
exports.hasMetricsFeature = hasMetricsFeature;
function hasAnamneseFeature(profile) {
    return hasAnyFeature(profile, ...types_1.ANAMNESEFEATURES);
}
exports.hasAnamneseFeature = hasAnamneseFeature;
function hasR4cFactoringFeature(profile) {
    return hasAnyFeature(profile, ...types_1.FACTORINGFEATURES);
}
exports.hasR4cFactoringFeature = hasR4cFactoringFeature;
function hasAnyR4cTabletFeature(profile) {
    return hasAnyFeature(profile, types_1.FEATURES.FACTORINGEWE, ...types_1.DOCUMENTSFEATURES, ...types_1.ANAMNESEFEATURES);
}
exports.hasAnyR4cTabletFeature = hasAnyR4cTabletFeature;
function hasFeature(profile, feature) {
    if (!profile?.lizenzen || !feature) {
        return false;
    }
    return profile?.lizenzen?.indexOf(feature) >= 0;
}
exports.hasFeature = hasFeature;
function hasAnyR4cFeature(profile) {
    return hasAnyFeature(profile, ...types_1.R4CFEATURES);
}
exports.hasAnyR4cFeature = hasAnyR4cFeature;
function hasAnyFeature(profile, ...features) {
    if (!profile) {
        return false;
    }
    for (const f of features) {
        if (!profile?.lizenzen) {
            return false;
        }
        if (profile?.lizenzen.indexOf(f) >= 0) {
            return true;
        }
    }
    return false;
}
exports.hasAnyFeature = hasAnyFeature;
function hasAllFeatures(profile, ...features) {
    for (const f of features) {
        if (!profile?.lizenzen) {
            return false;
        }
        if (profile?.lizenzen?.indexOf(f) < 0) {
            return false;
        }
    }
    return true;
}
exports.hasAllFeatures = hasAllFeatures;
function isCharlyAnalyticsCustomer(profile) {
    return isCharlyAnalyticsCustomerByPackage(profile?.lizenzInfo?.packageName);
}
exports.isCharlyAnalyticsCustomer = isCharlyAnalyticsCustomer;
function isCharlyAnalyticsCustomerByPackage(packagename) {
    return packagename === types_1.PACKAGES.CHARLYANALYTICS;
}
exports.isCharlyAnalyticsCustomerByPackage = isCharlyAnalyticsCustomerByPackage;
function getShortFeatures(features) {
    return Array.from(features
        .filter(k => Object.prototype.hasOwnProperty.call(types_1.ShortFeaturesMap, k))
        .map(k => types_1.ShortFeaturesMap[k])
        .reduce((acc, feature) => {
        if (!acc.some(item => item.short === feature.short)) {
            acc.push(feature);
        }
        return acc;
    }, [])
        .sort((a, b) => {
        const lengthDiff = a.short.length - b.short.length;
        if (lengthDiff !== 0) {
            return lengthDiff;
        }
        const aLower = a.short.toLowerCase();
        const bLower = b.short.toLowerCase();
        if (aLower < bLower) {
            return -1;
        }
        if (aLower > bLower) {
            return 1;
        }
        return 0;
    })
        .filter(k => k !== undefined && k !== null));
}
exports.getShortFeatures = getShortFeatures;
function hasAlarm(rolle, key) {
    let notInEmpfang = [
        'assistenz',
        'kontrolliert',
        'leistungen',
        'letztePSI',
        'paRoentgen',
        'psiBefund',
        'letztePA',
        'behandlerLeistungen',
    ];
    if (rolle === 'empfang') {
        return !notInEmpfang.includes(key);
    }
    return true;
}
exports.hasAlarm = hasAlarm;
function terminstatusKeyString(status) {
    switch (status) {
        case types_1.TerminStatusType.OFFEN:
            return 'OFFEN';
        case types_1.TerminStatusType.OHNE:
            return 'OHNE';
        case types_1.TerminStatusType.OK:
            return 'OK';
        case types_1.TerminStatusType.NICHTERSCHIENEN:
            return 'NICHTERSCHIENEN';
        case types_1.TerminStatusType.KURZFRISTIG_ABGESAGT:
            return 'KURZFRISTIG_ABGESAGT';
        case types_1.TerminStatusType.FRISTGERECHT_ABGESAGT:
            return 'FRISTGERECHT_ABGESAGT';
        case types_1.TerminStatusType.PRAXIS_HAT_ABGESAGT:
            return 'PRAXIS_HAT_ABGESAGT';
        case types_1.TerminStatusType.KONTROLLIERT_BEHANDLER_OK:
            return 'KONTROLLIERT_BEHANDLER_OK';
        case types_1.TerminStatusType.KONTROLLIERT_BEHANDLER_NICHTERSCHIENEN:
            return 'KONTROLLIERT_BEHANDLER_NICHTERSCHIENEN';
        case types_1.TerminStatusType.KONTROLLIERT_BEHANDLER_KURZFRISTIG_ABGESAGT:
            return 'KONTROLLIERT_BEHANDLER_KURZFRISTIG_ABGESAGT';
        case types_1.TerminStatusType.KONTROLLIERT_BEHANDLER_FRISTGERECHT_ABGESAGT:
            return 'KONTROLLIERT_BEHANDLER_FRISTGERECHT_ABGESAGT';
        case types_1.TerminStatusType.KONTROLLIERT_BEHANDLER_PRAXIS_HAT_ABGESAGT:
            return 'KONTROLLIERT_BEHANDLER_PRAXIS_HAT_ABGESAGT';
        case types_1.TerminStatusType.KONTROLLIERT_ABRECHNUNG_OK:
            return 'KONTROLLIERT_ABRECHNUNG_OK';
        case types_1.TerminStatusType.KONTROLLIERT_ABRECHNUNG_NICHTERSCHIENEN:
            return 'KONTROLLIERT_ABRECHNUNG_NICHTERSCHIENEN';
        case types_1.TerminStatusType.KONTROLLIERT_ABRECHNUNG_KURZFRISTIG_ABGESAGT:
            return 'KONTROLLIERT_ABRECHNUNG_KURZFRISTIG_ABGESAGT';
        case types_1.TerminStatusType.KONTROLLIERT_ABRECHNUNG_FRISTGERECHT_ABGESAGT:
            return 'KONTROLLIERT_ABRECHNUNG_FRISTGERECHT_ABGESAGT';
        case types_1.TerminStatusType.KONTROLLIERT_ABRECHNUNG_PRAXIS_HAT_ABGESAGT:
            return 'KONTROLLIERT_ABRECHNUNG_PRAXIS_HAT_ABGESAGT';
        case types_1.TerminStatusType.KONTROLLIERT_ALLE_OK:
            return 'KONTROLLIERT_ALLE_OK';
        case types_1.TerminStatusType.KONTROLLIERT_ALLE_NICHTERSCHIENEN:
            return 'KONTROLLIERT_ALLE_NICHTERSCHIENEN';
        case types_1.TerminStatusType.KONTROLLIERT_ALLE_KURZFRISTIG_ABGESAGT:
            return 'KONTROLLIERT_ALLE_KURZFRISTIG_ABGESAGT';
        case types_1.TerminStatusType.KONTROLLIERT_ALLE_FRISTGERECHT_ABGESAGT:
            return 'KONTROLLIERT_ALLE_FRISTGERECHT_ABGESAGT';
        case types_1.TerminStatusType.KONTROLLIERT_ALLE_PRAXIS_HAT_ABGESAGT:
            return 'KONTROLLIERT_ALLE_PRAXIS_HAT_ABGESAGT';
        case types_1.TerminStatusType.VIRTUELL:
            return 'VIRTUELL';
        default:
            return '?';
    }
}
exports.terminstatusKeyString = terminstatusKeyString;
function terminstatusName(status, stringForOhneStatus, mitKontrolliert = true) {
    let nn = '';
    let k = '';
    switch (status) {
        case types_1.TerminStatusType.OFFEN:
            nn = 'Offen';
            break;
        case types_1.TerminStatusType.OHNE:
            nn = stringForOhneStatus ? stringForOhneStatus : '-';
            break;
        case types_1.TerminStatusType.OK:
            nn = 'OK';
            break;
        case types_1.TerminStatusType.NICHTERSCHIENEN:
            nn = 'NE';
            break;
        case types_1.TerminStatusType.KURZFRISTIG_ABGESAGT:
            nn = 'KA';
            break;
        case types_1.TerminStatusType.FRISTGERECHT_ABGESAGT:
            nn = 'FA';
            break;
        case types_1.TerminStatusType.PRAXIS_HAT_ABGESAGT:
            nn = 'PA';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_BEHANDLER_OK:
            nn = 'OK';
            k = ' B';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_BEHANDLER_NICHTERSCHIENEN:
            nn = 'NE';
            k = ' B';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_BEHANDLER_KURZFRISTIG_ABGESAGT:
            nn = 'KA';
            k = ' B';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_BEHANDLER_FRISTGERECHT_ABGESAGT:
            nn = 'FA';
            k = ' B';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_BEHANDLER_PRAXIS_HAT_ABGESAGT:
            nn = 'PA';
            k = ' B';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ABRECHNUNG_OK:
            nn = 'OK';
            k = ' A';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ABRECHNUNG_NICHTERSCHIENEN:
            nn = 'NE';
            k = ' A';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ABRECHNUNG_KURZFRISTIG_ABGESAGT:
            nn = 'KA';
            k = ' A';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ABRECHNUNG_FRISTGERECHT_ABGESAGT:
            nn = 'FA';
            k = ' A';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ABRECHNUNG_PRAXIS_HAT_ABGESAGT:
            nn = 'PA';
            k = ' A';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ALLE_OK:
            nn = 'OK';
            k = ' K';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ALLE_NICHTERSCHIENEN:
            nn = 'NE';
            k = ' K';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ALLE_KURZFRISTIG_ABGESAGT:
            nn = 'KA';
            k = ' K';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ALLE_FRISTGERECHT_ABGESAGT:
            nn = 'FA';
            k = ' K';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ALLE_PRAXIS_HAT_ABGESAGT:
            nn = 'PA';
            k = ' K';
            break;
        case types_1.TerminStatusType.VIRTUELL:
            nn = 'VIRTUELL';
            break;
        default:
            nn = stringForOhneStatus ? stringForOhneStatus : '-';
    }
    return mitKontrolliert ? nn + k : nn;
}
exports.terminstatusName = terminstatusName;
function terminstatusNiceName(status, stringForOhneStatus, mitKontrolliert = true) {
    let nn = '';
    let k = '';
    switch (status) {
        case types_1.TerminStatusType.OFFEN:
            nn = 'Offener Termin';
            break;
        case types_1.TerminStatusType.OHNE:
            nn = stringForOhneStatus ? stringForOhneStatus : 'Kein Status';
            break;
        case types_1.TerminStatusType.OK:
            nn = 'Termin hat stattgefunden';
            break;
        case types_1.TerminStatusType.NICHTERSCHIENEN:
            nn = 'Patient ist nicht erschienen';
            break;
        case types_1.TerminStatusType.KURZFRISTIG_ABGESAGT:
            nn = 'Patient hat kurzfristig abgesagt';
            break;
        case types_1.TerminStatusType.FRISTGERECHT_ABGESAGT:
            nn = 'Patient hat fristgerecht abgesagt';
            break;
        case types_1.TerminStatusType.PRAXIS_HAT_ABGESAGT:
            nn = 'Praxis hat abgesagt';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_BEHANDLER_OK:
            nn = 'Termin hat stattgefunden';
            k = ', Behandler hat kontrolliert';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_BEHANDLER_NICHTERSCHIENEN:
            nn = 'Patient ist nicht erschienen';
            k = ', Behandler hat kontrolliert';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_BEHANDLER_KURZFRISTIG_ABGESAGT:
            nn = 'Patient hat kurzfristig abgesagt';
            k = ', Behandler hat kontrolliert';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_BEHANDLER_FRISTGERECHT_ABGESAGT:
            nn = 'Patient hat fristgerecht abgesagt';
            k = ', Behandler hat kontrolliert';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_BEHANDLER_PRAXIS_HAT_ABGESAGT:
            nn = 'Praxis hat abgesagt';
            k = ', Behandler hat kontrolliert';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ABRECHNUNG_OK:
            nn = 'Termin hat stattgefunden';
            k = ', Abrechnung hat kontrolliert';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ABRECHNUNG_NICHTERSCHIENEN:
            nn = 'Patient ist nicht erschienen';
            k = ', Abrechnung hat kontrolliert';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ABRECHNUNG_KURZFRISTIG_ABGESAGT:
            nn = 'Patient hat kurzfristig abgesagt';
            k = ', Abrechnung hat kontrolliert';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ABRECHNUNG_FRISTGERECHT_ABGESAGT:
            nn = 'Patient hat fristgerecht abgesagt';
            k = ', Abrechnung hat kontrolliert';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ABRECHNUNG_PRAXIS_HAT_ABGESAGT:
            nn = 'Praxis hat abgesagt';
            k = ', Abrechnung hat kontrolliert';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ALLE_OK:
            nn = 'Termin hat stattgefunden';
            k = ', Behandler und Abrechnung haben kontrolliert';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ALLE_NICHTERSCHIENEN:
            nn = 'Patient ist nicht erschienen';
            k = ', Behandler und Abrechnung haben kontrolliert';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ALLE_KURZFRISTIG_ABGESAGT:
            nn = 'Patient hat kurzfristig abgesagt';
            k = ', Behandler und Abrechnung haben kontrolliert';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ALLE_FRISTGERECHT_ABGESAGT:
            nn = 'Patient hat fristgerecht abgesagt';
            k = ', Behandler und Abrechnung haben kontrolliert';
            break;
        case types_1.TerminStatusType.KONTROLLIERT_ALLE_PRAXIS_HAT_ABGESAGT:
            nn = 'Praxis hat abgesagt';
            k = ', Behandler und Abrechnung haben kontrolliert';
            break;
        case types_1.TerminStatusType.VIRTUELL:
            nn = 'Virtueller Termin';
        default:
            nn = stringForOhneStatus ? stringForOhneStatus : '-';
    }
    return mitKontrolliert ? nn + k : nn;
}
exports.terminstatusNiceName = terminstatusNiceName;
exports.abrechnungsArten = [
    {
        name: abrechnungsArtNiceName(types_1.AbrechnungsArt.GOZ_ALLG, true),
        artName: abrechnungsArtNiceName(types_1.AbrechnungsArt.GOZ_ALLG, false),
        short: abrechnungsArtShortName(types_1.AbrechnungsArt.GOZ_ALLG),
        values: [types_1.AbrechnungsArt.GOZ_ALLG],
        typ: types_1.BemaGozType.GOZ,
    },
    {
        name: abrechnungsArtNiceName(types_1.AbrechnungsArt.GOZ_KONS, true),
        artName: abrechnungsArtNiceName(types_1.AbrechnungsArt.GOZ_KONS, false),
        short: abrechnungsArtShortName(types_1.AbrechnungsArt.GOZ_KONS),
        values: [types_1.AbrechnungsArt.GOZ_KONS, types_1.AbrechnungsArt.GOZ_CHIR],
        typ: types_1.BemaGozType.GOZ,
    },
    // {
    //   name: abrechnungsArtNiceName(AbrechnungsArt.GOZ_CHIR, true),
    //   artName: abrechnungsArtNiceName(AbrechnungsArt.GOZ_CHIR, false),
    //   short: abrechnungsArtShortName(AbrechnungsArt.GOZ_CHIR),
    //   values: AbrechnungsArt.GOZ_CHIR,
    //   typ: BemaGozType.GOZ,
    // },
    {
        name: abrechnungsArtNiceName(types_1.AbrechnungsArt.GOZ_IMPL, true),
        artName: abrechnungsArtNiceName(types_1.AbrechnungsArt.GOZ_IMPL, false),
        short: abrechnungsArtShortName(types_1.AbrechnungsArt.GOZ_IMPL),
        values: [types_1.AbrechnungsArt.GOZ_IMPL],
        typ: types_1.BemaGozType.GOZ,
    },
    {
        name: abrechnungsArtNiceName(types_1.AbrechnungsArt.GOZ_PA, true),
        artName: abrechnungsArtNiceName(types_1.AbrechnungsArt.GOZ_PA, false),
        short: abrechnungsArtShortName(types_1.AbrechnungsArt.GOZ_PA),
        values: [types_1.AbrechnungsArt.GOZ_PA],
        typ: types_1.BemaGozType.GOZ,
    },
    {
        name: abrechnungsArtNiceName(types_1.AbrechnungsArt.GOZ_FA, true),
        artName: abrechnungsArtNiceName(types_1.AbrechnungsArt.GOZ_FA, false),
        short: abrechnungsArtShortName(types_1.AbrechnungsArt.GOZ_FA),
        values: [types_1.AbrechnungsArt.GOZ_FA],
        typ: types_1.BemaGozType.GOZ,
    },
    {
        name: abrechnungsArtNiceName(types_1.AbrechnungsArt.GOZ_ZE, true),
        artName: abrechnungsArtNiceName(types_1.AbrechnungsArt.GOZ_ZE, false),
        short: abrechnungsArtShortName(types_1.AbrechnungsArt.GOZ_ZE),
        values: [types_1.AbrechnungsArt.GOZ_ZE],
        typ: types_1.BemaGozType.GOZ,
    },
    {
        name: abrechnungsArtNiceName(types_1.AbrechnungsArt.GOZ_KFO, true),
        artName: abrechnungsArtNiceName(types_1.AbrechnungsArt.GOZ_KFO, false),
        short: abrechnungsArtShortName(types_1.AbrechnungsArt.GOZ_KFO),
        values: [types_1.AbrechnungsArt.GOZ_KFO],
        typ: types_1.BemaGozType.GOZ,
    },
    {
        name: abrechnungsArtNiceName(types_1.AbrechnungsArt.GOZ_PZR, true),
        artName: abrechnungsArtNiceName(types_1.AbrechnungsArt.GOZ_PZR, false),
        short: abrechnungsArtShortName(types_1.AbrechnungsArt.GOZ_PZR),
        values: [types_1.AbrechnungsArt.GOZ_PZR],
        typ: types_1.BemaGozType.GOZ,
    },
    {
        name: abrechnungsArtNiceName(types_1.AbrechnungsArt.BEMA_ZE, true),
        artName: abrechnungsArtNiceName(types_1.AbrechnungsArt.BEMA_ZE, false),
        short: abrechnungsArtShortName(types_1.AbrechnungsArt.BEMA_ZE),
        values: [types_1.AbrechnungsArt.BEMA_ZE],
        typ: types_1.BemaGozType.BEMA,
    },
    {
        name: abrechnungsArtNiceName(types_1.AbrechnungsArt.BEMA_PA, true),
        artName: abrechnungsArtNiceName(types_1.AbrechnungsArt.BEMA_PA, false),
        short: abrechnungsArtShortName(types_1.AbrechnungsArt.BEMA_PA),
        values: [types_1.AbrechnungsArt.BEMA_PA],
        typ: types_1.BemaGozType.BEMA,
    },
    {
        name: abrechnungsArtNiceName(types_1.AbrechnungsArt.BEMA_KFO, true),
        artName: abrechnungsArtNiceName(types_1.AbrechnungsArt.BEMA_KFO, false),
        short: abrechnungsArtShortName(types_1.AbrechnungsArt.BEMA_KFO),
        values: [types_1.AbrechnungsArt.BEMA_KFO],
        typ: types_1.BemaGozType.BEMA,
    },
    {
        name: abrechnungsArtNiceName(types_1.AbrechnungsArt.BEMA_KONSCHIR, true),
        artName: abrechnungsArtNiceName(types_1.AbrechnungsArt.BEMA_KONSCHIR, false),
        short: abrechnungsArtShortName(types_1.AbrechnungsArt.BEMA_KONSCHIR),
        values: [types_1.AbrechnungsArt.BEMA_KONSCHIR, types_1.AbrechnungsArt.BEMA_IP],
        typ: types_1.BemaGozType.BEMA,
    },
    {
        name: abrechnungsArtNiceName(types_1.AbrechnungsArt.BEMA_KIEFERB, true),
        artName: abrechnungsArtNiceName(types_1.AbrechnungsArt.BEMA_KIEFERB, false),
        short: abrechnungsArtShortName(types_1.AbrechnungsArt.BEMA_KIEFERB),
        values: [types_1.AbrechnungsArt.BEMA_KIEFERB],
        typ: types_1.BemaGozType.BEMA,
    },
    // {
    //   name: abrechnungsArtNiceName(AbrechnungsArt.BEMA_IP, true),
    //   artName: abrechnungsArtNiceName(AbrechnungsArt.BEMA_IP, false),
    //   short: abrechnungsArtShortName(AbrechnungsArt.BEMA_IP),
    //   values: AbrechnungsArt.BEMA_IP,
    //   typ: BemaGozType.BEMA,
    // },
    {
        name: abrechnungsArtNiceName(types_1.AbrechnungsArt.BEMA_BESUCH, true),
        artName: abrechnungsArtNiceName(types_1.AbrechnungsArt.BEMA_BESUCH, false),
        short: abrechnungsArtShortName(types_1.AbrechnungsArt.BEMA_BESUCH),
        values: [types_1.AbrechnungsArt.BEMA_BESUCH],
        typ: types_1.BemaGozType.BEMA,
    },
    {
        name: abrechnungsArtNiceName(types_1.AbrechnungsArt.UNBEKANNT, true),
        artName: abrechnungsArtNiceName(types_1.AbrechnungsArt.UNBEKANNT, false),
        short: abrechnungsArtShortName(types_1.AbrechnungsArt.UNBEKANNT),
        values: [types_1.AbrechnungsArt.UNBEKANNT],
        typ: types_1.BemaGozType.UNKNOWN,
    },
];
function abrechnungsArtNiceName(aa, withPrefix) {
    switch (aa) {
        case types_1.AbrechnungsArt.UNBEKANNT:
            return 'Sonstige';
        case types_1.AbrechnungsArt.GOZ_ALLG:
            return withPrefix ? 'Privat Allg.' : 'Allg.';
        case types_1.AbrechnungsArt.GOZ_KONS:
            return withPrefix ? 'Privat KCh' : 'KCh';
        case types_1.AbrechnungsArt.GOZ_CHIR:
            return withPrefix ? 'Privat Ch' : 'Ch';
        case types_1.AbrechnungsArt.GOZ_IMPL:
            return withPrefix ? 'Privat Impl' : 'Impl';
        case types_1.AbrechnungsArt.GOZ_PA:
            return withPrefix ? 'Privat PA' : 'PA';
        case types_1.AbrechnungsArt.GOZ_FA:
            return withPrefix ? 'Privat FA' : 'FA';
        case types_1.AbrechnungsArt.GOZ_ZE:
            return withPrefix ? 'Privat ZE' : 'ZE';
        case types_1.AbrechnungsArt.GOZ_KFO:
            return withPrefix ? 'Privat KFO' : 'KFO';
        case types_1.AbrechnungsArt.GOZ_PZR:
            return withPrefix ? 'Privat PZR' : 'PZR';
        case types_1.AbrechnungsArt.BEMA_ZE:
            return withPrefix ? 'Kasse ZE' : 'ZE';
        case types_1.AbrechnungsArt.BEMA_PA:
            return withPrefix ? 'Kasse PA' : 'PA';
        case types_1.AbrechnungsArt.BEMA_KFO:
            return withPrefix ? 'Kasse KFO' : 'KFO';
        case types_1.AbrechnungsArt.BEMA_KONSCHIR:
            return withPrefix ? 'Kasse KCh' : 'KCh';
        case types_1.AbrechnungsArt.BEMA_KIEFERB:
            return withPrefix ? 'Kasse KBr' : 'KBr';
        case types_1.AbrechnungsArt.BEMA_IP:
            return withPrefix ? 'Kasse IP' : 'IP';
        case types_1.AbrechnungsArt.BEMA_BESUCH:
            return withPrefix ? 'Kasse Besuch' : 'Besuch';
    }
}
exports.abrechnungsArtNiceName = abrechnungsArtNiceName;
function abrechnungsArtShortName(aa) {
    switch (aa) {
        case types_1.AbrechnungsArt.UNBEKANNT:
            return 'Sonstige';
        case types_1.AbrechnungsArt.GOZ_ALLG:
            return 'GozAllg';
        case types_1.AbrechnungsArt.GOZ_KONS:
            return 'GozKCh';
        case types_1.AbrechnungsArt.GOZ_CHIR:
            return 'GozCh';
        case types_1.AbrechnungsArt.GOZ_IMPL:
            return 'GozImpl';
        case types_1.AbrechnungsArt.GOZ_PA:
            return 'GozPA';
        case types_1.AbrechnungsArt.GOZ_FA:
            return 'GozFA';
        case types_1.AbrechnungsArt.GOZ_ZE:
            return 'GozZE';
        case types_1.AbrechnungsArt.GOZ_KFO:
            return 'GozKFO';
        case types_1.AbrechnungsArt.GOZ_PZR:
            return 'GozPZR';
        case types_1.AbrechnungsArt.BEMA_ZE:
            return 'BemaZE';
        case types_1.AbrechnungsArt.BEMA_PA:
            return 'BemaPA';
        case types_1.AbrechnungsArt.BEMA_KFO:
            return 'BemaKFO';
        case types_1.AbrechnungsArt.BEMA_KONSCHIR:
            return 'BemaKCh';
        case types_1.AbrechnungsArt.BEMA_KIEFERB:
            return 'BemaKBr';
        case types_1.AbrechnungsArt.BEMA_IP:
            return 'BemaIP';
        case types_1.AbrechnungsArt.BEMA_BESUCH:
            return 'BemaBesuch';
    }
}
exports.abrechnungsArtShortName = abrechnungsArtShortName;
function benchmarkUnitShortName(unit) {
    switch (unit) {
        case types_1.BenchmarkConfigUnitType.PATIENTEN:
            return 'P';
        case types_1.BenchmarkConfigUnitType.TERMINE:
            return 'T';
        case types_1.BenchmarkConfigUnitType.EURO:
            return '€';
        case types_1.BenchmarkConfigUnitType.PERCENT:
            return '%';
        case types_1.BenchmarkConfigUnitType.HKP:
            return 'HKP';
        case types_1.BenchmarkConfigUnitType.HOURSMINUTES:
            return 'h:m';
        case types_1.BenchmarkConfigUnitType.DAYS:
            return 'd';
        case types_1.BenchmarkConfigUnitType.MONTHS:
            return 'M';
        case types_1.BenchmarkConfigUnitType.YEARS:
            return 'J';
        case types_1.BenchmarkConfigUnitType.MINUTES:
            return 'min';
        case types_1.BenchmarkConfigUnitType.FUELLUNGEN:
            return 'F';
        case types_1.BenchmarkConfigUnitType.FUELLUNGENPROPATIENT:
            return 'F/P';
        case types_1.BenchmarkConfigUnitType.IMPLANTATE:
            return 'I';
        case types_1.BenchmarkConfigUnitType.FAKTOR:
            return 'x';
    }
    return `${unit}`;
}
exports.benchmarkUnitShortName = benchmarkUnitShortName;
function benchmarkUnitNiceName(unit) {
    switch (unit) {
        case types_1.BenchmarkConfigUnitType.PATIENTEN:
            return 'Patienten';
        case types_1.BenchmarkConfigUnitType.TERMINE:
            return 'Termine';
        case types_1.BenchmarkConfigUnitType.EURO:
            return '€';
        case types_1.BenchmarkConfigUnitType.PERCENT:
            return '%';
        case types_1.BenchmarkConfigUnitType.HKP:
            return 'HKP';
        case types_1.BenchmarkConfigUnitType.HOURSMINUTES:
            return 'h:m';
        case types_1.BenchmarkConfigUnitType.DAYS:
            return 'Tage';
        case types_1.BenchmarkConfigUnitType.MONTHS:
            return 'Monate';
        case types_1.BenchmarkConfigUnitType.YEARS:
            return 'Jahre';
        case types_1.BenchmarkConfigUnitType.MINUTES:
            return 'Minuten';
        case types_1.BenchmarkConfigUnitType.FUELLUNGEN:
            return 'Füllungen';
        case types_1.BenchmarkConfigUnitType.FUELLUNGENPROPATIENT:
            return 'Füllungen pro Patient';
        case types_1.BenchmarkConfigUnitType.IMPLANTATE:
            return 'Implantate';
        case types_1.BenchmarkConfigUnitType.FAKTOR:
            return 'x';
    }
    return `${unit}`;
}
exports.benchmarkUnitNiceName = benchmarkUnitNiceName;
/**
 * if multiple ui/user changes can trigger an async operation where you care about the result of the last triggered operation
 * use this method to always receive the outcome of the last operation no matter how the timings of the multiple async operations are
 *
 * usage: wrap async operation with this method and use the wrapped function
 *
 * let safeAsyncOperation = ensureLastRequestPrecedence(asynOperation);
 *
 * let promiseA = safeAsyncOperation();
 * let promiseB = safeAsyncOperation();
 *
 * await promiseA; // this will never resolve, since there was another incovation after its call
 *
 * safeAsyncOperation only resolves/rejects if there was no other invocation triggered after it.
 *
 * eg usage in components:
 * ```
 * paramsChanged() {
 *    this.loading = true;
 *    try {
 *      let data = await safeAsyncOperation(this.params);
 *    } catch(e) {
 *      alert(e);
 *    }
 *    this.loading = false;
 * }
 * ```
 *
 * @param asyncCall
 * @returns
 */
const ensureLastRequestPrecedence = (asyncCall) => {
    let lastPromise;
    return (...args) => {
        // eslint-disable-next-line prefer-spread
        let nowPromise = asyncCall.apply(null, args);
        lastPromise = nowPromise;
        // eslint-disable-next-line @typescript-eslint/no-misused-promises, no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                // eslint-disable-next-line @typescript-eslint/await-thenable
                let res = await nowPromise;
                if (lastPromise === nowPromise) {
                    resolve(res);
                }
            }
            catch (e) {
                if (lastPromise === nowPromise) {
                    reject(e);
                }
            }
        });
    };
};
exports.ensureLastRequestPrecedence = ensureLastRequestPrecedence;
function sleep(ms = 0) {
    return new Promise(r => setTimeout(r, ms));
}
exports.sleep = sleep;
function isTrue(thing) {
    if (typeof thing === 'boolean') {
        return thing === true;
    }
    if (typeof thing === 'string') {
        let thingLow = thing.toLowerCase();
        return thingLow === 't' || thingLow === 'true' || thingLow === '1';
    }
    if (typeof thing === 'number') {
        return thing === 1;
    }
    return false;
}
exports.isTrue = isTrue;
function jaNein(toggle, ja = 'Ja', nein = 'Nein') {
    return toggle ? ja : nein;
}
exports.jaNein = jaNein;
function createPercentage(count, total, roundingPrecision = -1) {
    if (!count) {
        return { count: 0, percentage: 0 };
    }
    return { count, percentage: getPercentage(count, total, roundingPrecision) };
}
exports.createPercentage = createPercentage;
function getPercentage(count, total, roundingPrecision = -1) {
    let p = (count / total) * 100;
    return round(p, roundingPrecision);
}
exports.getPercentage = getPercentage;
function round(n, precision) {
    if (precision > 0) {
        let factor = Math.pow(10, precision);
        let tempNumber = n * factor;
        let roundedTempNumber = Math.round(tempNumber);
        return roundedTempNumber / factor;
    }
    return n;
}
exports.round = round;
function parsePostgresVersion(v) {
    if (!v || !v.includes(',') || !v.includes('.')) {
        return {
            raw: v,
            short: v,
            major: '?',
            minor: '?',
            patch: '?',
        };
    }
    const vshortRaw = v.toLowerCase().replaceAll('postgresql', '').trim();
    const [precomma] = vshortRaw.split(',');
    const [vshort] = precomma.split(' ');
    const [major, minor, patch] = vshort.split('.');
    return {
        raw: v,
        short: vshort,
        major,
        minor,
        patch,
    };
}
exports.parsePostgresVersion = parsePostgresVersion;
exports.TASK_PREFIX_IMPORT = 'I';
exports.TASK_PREFIX_TRIGGER = 'T';
function taskIdFromImport(info) {
    if (info) {
        return `${exports.TASK_PREFIX_IMPORT}:${info.incremental ? 'INC' : 'FULL'}:${info.cid}:${info.id}`;
    }
    return undefined;
}
exports.taskIdFromImport = taskIdFromImport;
function taskIdForTriggerRaw(cid, type, id) {
    return `${exports.TASK_PREFIX_TRIGGER}:${cid}:${type}:${id}`;
}
exports.taskIdForTriggerRaw = taskIdForTriggerRaw;
function isImportTask(taskId) {
    return taskId.startsWith(exports.TASK_PREFIX_IMPORT);
}
exports.isImportTask = isImportTask;
function isTriggerTask(taskId) {
    return taskId.startsWith(exports.TASK_PREFIX_TRIGGER);
}
exports.isTriggerTask = isTriggerTask;
/**
 * NOT secure hash function
 * credits: https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
 */
function simpleHashCode(inputString) {
    let hash = 0;
    if (inputString.length === 0) {
        return hash;
    }
    for (let i = 0; i < inputString.length; i++) {
        let char = inputString.charCodeAt(i);
        // tslint:disable-next-line
        hash = (hash << 5) - hash + char;
        // tslint:disable-next-line
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}
exports.simpleHashCode = simpleHashCode;
function prettyCharlyFileName(file) {
    if (file.endsWith('.doku')) {
        file = file.replace(/\.doku$/, '');
    }
    // remove file extension
    if (file.indexOf('.') > 0) {
        file = file.substring(0, file.lastIndexOf('.'));
    }
    if (extractCharlyDateFromFileName(file)) {
        file = file.substring(8);
    }
    return file;
}
exports.prettyCharlyFileName = prettyCharlyFileName;
function extractCharlyDateFromFileName(file) {
    if (file.length > 8) {
        let dateStart = file.substring(0, 8);
        if (!isNaN(+dateStart)) {
            let y = dateStart.substring(0, 4);
            let m = dateStart.substring(4, 4 + 2);
            let d = dateStart.substring(6, 6 + 2);
            return `${d}.${m}.${y}`;
        }
    }
    return '';
}
exports.extractCharlyDateFromFileName = extractCharlyDateFromFileName;
function sanitizeFilenameForCharly(fileName) {
    let replacementMap = {
        ä: 'ae',
        ö: 'oe',
        ü: 'ue',
        ß: 'ss',
        Ä: 'Ae',
        Ö: 'Oe',
        Ü: 'Ue',
        '*': 's',
        '+': 'p',
        ' ': '_',
    };
    return (fileName
        .split('')
        // replace problematic characters
        .map(char => replacementMap[char] || char)
        // only allow these characters
        // disallow # since it causes problems with the document preview in charly mac installations
        // disallow äöüÄÖÜ because it chauses problems in .inputname files
        .filter(char => /[a-zA-Z_.0-9-]/.test(char))
        .join(''));
}
exports.sanitizeFilenameForCharly = sanitizeFilenameForCharly;
// we need this temporarly to create stochwort version which can be used as column name in the database
function slugifyStichwort(stichwort) {
    const ss = sanitizeFilenameForCharly(stichwort).replaceAll('.', '_');
    return ss[0].toLocaleUpperCase() + ss.substring(1);
}
exports.slugifyStichwort = slugifyStichwort;
/**
 * Creates a Promise that is resolved with an object of results when all of the provided Promises
 * resolve, or rejected when any Promise is rejected.
 * @param promiseMap An object of Promises.
 * @returns A new Promise.
 */
async function promiseAllMap(promiseMap) {
    let resultsArray = await Promise.all(Object.values(promiseMap));
    let resultObj = {};
    Object.keys(promiseMap).forEach((key, i) => {
        resultObj[key] = resultsArray[i];
    });
    return resultObj;
}
exports.promiseAllMap = promiseAllMap;
// get years easter date
function ostersonntag(jahr) {
    const a = jahr % 19;
    const b = Math.floor(jahr / 100);
    const c = jahr % 100;
    const d = Math.floor(b / 4);
    const e = b % 4;
    const f = Math.floor((b + 8) / 25);
    const g = Math.floor((b - f + 1) / 3);
    const h = (19 * a + b - d - g + 15) % 30;
    const i = Math.floor(c / 4);
    const k = c % 4;
    const l = (32 + 2 * e + 2 * i - h - k) % 7;
    const m = Math.floor((a + 11 * h + 22 * l) / 451);
    const n = Math.floor((h + l - 7 * m + 114) / 31);
    const p = (h + l - 7 * m + 114) % 31;
    return { jahr, monat: n, tag: p + 1 };
}
exports.ostersonntag = ostersonntag;
function osterSonntagAsUTCDate(jahr) {
    const o = ostersonntag(jahr);
    return new Date(Date.UTC(jahr, o.monat - 1, o.tag));
}
exports.osterSonntagAsUTCDate = osterSonntagAsUTCDate;
function createEmptyDashBoardData(profile) {
    return {
        cid: profile.cid,
        clientName: profile.praxisKurzName,
        lastFullImport: undefined,
        lastIncrementalImport: undefined,
        firstAppointment: undefined,
        lastAppointment: undefined,
        clientActivity: {
            metrics: {
                lastmonth: { sum: -1, chart: [] },
                lastyear: { sum: -1, chart: [] },
            },
            r4c: {
                factoring: {
                    lastmonth: { sum: -1, chart: [] },
                    lastyear: { sum: -1, chart: [] },
                },
                documents: {
                    lastmonth: { sum: -1, chart: [] },
                    lastyear: { sum: -1, chart: [] },
                },
                anamnese: {
                    lastmonth: { sum: -1, chart: [] },
                    lastyear: { sum: -1, chart: [] },
                },
            },
        },
        aktivePatienten: -1,
        neuPatienten: -1,
        umsatzLetztesJahr: -1,
        umsatzAktuellesJahr: -1,
        termineLetztesJahr: -1,
        termineAktuellesJahr: -1,
        angeboteneBehandlungszeitAktuellesJahr: -1,
        angeboteneBehandlungszeitLetztesJahr: -1,
        anzahlPatienten: -1,
        anzahlBenutzer: -1,
        anzahlTermine: -1,
        anzahlZimmer: -1,
        anzahlBehandler: { za: -1, pzr: -1 },
        aktiveBehandler: {
            cid: '',
            monat: '',
            za: -1,
            pzr: -1,
            billable: -1,
            billableRounded: -1,
            raw: {},
        },
        lastActive: undefined,
        lastActiveUsers: undefined,
        realMitarbeiter: undefined,
        importStepDurations: undefined,
        fullImportStatistics: undefined,
        incrementalImportStatistics: undefined,
        dbInfo: undefined,
        pvsInfo: { version: '-' },
        screening: { kzv: '-', laborbel: '-' },
        stripe: { metricsBehandlerLizenzen: 0, metricsType: 'none', mrr: 0, products: [] },
        collected: new Date(),
        heuteScore: {
            empfang: {
                avg: 0,
                chart: [],
            },
            prophylaxe: {
                avg: 0,
                chart: [],
            },
            zahnarzt: {
                avg: 0,
                chart: [],
            },
            abrechnung: {
                avg: 0,
                chart: [],
            },
            gesamt: {
                avg: 0,
                chart: [],
            },
        },
        abrechnungsScore: {
            currentQuarter: undefined,
            lastQuarter: undefined,
        },
        hkpCounts: {
            genehmigt: -1,
            ohneTermin: -1,
            genehmigtOhneTermin: -1,
        },
    };
}
exports.createEmptyDashBoardData = createEmptyDashBoardData;
/**
 * check for overlapping of two date ranges
 * @param r1from date range 1 from
 * @param r1to date range 1 to
 * @param r2from date range 2 from
 * @param r2to date range 2 to
 * @returns if the two date ranges overlap
 */
function dateRangeOverlap(r1from, r1to, r2from, r2to) {
    // some checks
    if (!r1from || !r1to || !r2from || !r2to) {
        return { ok: false, error: 'one or more dates are undefined' };
    }
    if (r1from.valueOf() > r1to.valueOf() || r2from.valueOf() > r2to.valueOf()) {
        return { ok: false, error: 'a from date is after to date' };
    }
    // check if r1 is before r2
    if (r1to.valueOf() < r2from.valueOf()) {
        return { ok: true, overlap: false, case: 'r1to < r2from' };
    }
    // check if r2 is before r1
    if (r2to.valueOf() < r1from.valueOf()) {
        return { ok: true, overlap: false, case: 'r2to < r1from' };
    }
    // r1 includes r2
    if (r1from.valueOf() <= r2from.valueOf() && r1to.valueOf() >= r2to.valueOf()) {
        return { ok: true, overlap: true, case: 'r1 includes r2' };
    }
    // r2 includes r1
    if (r2from.valueOf() <= r1from.valueOf() && r2to.valueOf() >= r1to.valueOf()) {
        return { ok: true, overlap: true, case: 'r2 includes r1' };
    }
    // overlap
    if ((r1to.valueOf() >= r2to.valueOf() && r1from.valueOf() >= r2from.valueOf()) ||
        (r2to.valueOf() >= r1to.valueOf() && r1from.valueOf() >= r1from.valueOf())) {
        return { ok: true, overlap: true, case: 'overlap' };
    }
    return { ok: true, overlap: false, case: 'unknown' };
}
exports.dateRangeOverlap = dateRangeOverlap;
function mapObject(obj, keyMapper, valueMapper) {
    const ret = {};
    if (obj) {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const v = obj[key];
                const mappedValue = valueMapper(v, key);
                if (mappedValue !== undefined) {
                    ret[keyMapper(key, v)] = mappedValue;
                }
            }
        }
    }
    return ret;
}
exports.mapObject = mapObject;
function logObjectModificationsProxy(targetObj) {
    // Helper function to recursively wrap objects or arrays in proxies
    function wrapObject(obj) {
        if (typeof obj !== 'object' || obj === null || obj.__isProxy) {
            return obj; // Return if it's not an object or already proxied
        }
        // Proxy handler to log property modifications
        const handler = {
            get(target, property) {
                return target[property]; // Simply return the property value
            },
            set(target, property, value) {
                console.log(`--> Property '${property}' set to`, value);
                target[property] = wrapObject(value); // Wrap new value if it is an object
                return true;
            },
            deleteProperty(target, property) {
                console.log(`--> Property '${property}' deleted`);
                return Reflect.deleteProperty(target, property);
            },
        };
        // Recursively wrap each nested object or array in a proxy
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                obj[key] = wrapObject(obj[key]);
            }
        }
        // Mark the object as a proxy to prevent re-wrapping
        Object.defineProperty(obj, '__isProxy', {
            value: true,
            enumerable: false,
            configurable: false,
            writable: false,
        });
        // Return the proxy-wrapped object
        return new Proxy(obj, handler);
    }
    return wrapObject(targetObj); // Start the wrapping process
}
exports.logObjectModificationsProxy = logObjectModificationsProxy;
